import React from 'react';
import { IoNavigate } from "react-icons/io5";
import { FaMapMarkerAlt } from 'react-icons/fa';
import { MdAir } from "react-icons/md";
import { CgTrack } from "react-icons/cg";
import { MdAdminPanelSettings } from "react-icons/md";
import { Link } from 'react-router-dom';
import logo from './Assets/magna.png';
import './MainMenu.css';

const MainMenu = () => {
  return (
    <div className="main-menu-container">
      <div className="logo-container">
        <img src={logo} alt="Company Logo" className="logo-image" />
      </div>
      <div className="button-container">
        <h2>Our Services</h2>
        <div className="button-group">
          <Link to="/directions" className="icon-container icon-container-1"> 
            <IoNavigate size={50} />
            <p className="icon-text">Get Direction</p>
          </Link>
          <Link to="/salespoi" className="icon-container icon-container-1"> 
            <FaMapMarkerAlt size={50} />
            <p className="icon-text">Get POI by routes</p>
          </Link>
          <Link to="/nearbypoi" className="icon-container icon-container-1"> 
            <FaMapMarkerAlt size={50} />
            <p className="icon-text">Get Nearby POI</p>
          </Link>
        </div>
        {/* <div className="button-group">
          <Link to="/maps-tracking" className="icon-container icon-container-3"> 
            <CgTrack size={50} />
            <p className="icon-text">Maps Tracker</p>
          </Link>
          <Link to="/admin" className="icon-container icon-container-4"> 
            <MdAdminPanelSettings size={50} />
            <p className="icon-text">Admin</p>
          </Link>
        </div>
        <div className='button-group'>
          <Link to="/road-check" className="icon-container icon-container-3"> 
            <CgTrack size={50} />
            <p className="icon-text">Road Details</p>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default MainMenu;
