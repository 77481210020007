import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './LoginPage';
import MainMenu from './MainMenu';
import DirectionAPI from './DirectionAPI';
import SalesPOI from './salesPOI';
import NearPOI from './nearbyPOI';
import AirQualityAPI from './AirQualityAPI';
import MapsTracking from './MapsTracking';
import RoadCheck from './RoadCheck';
import Admin from './Admin';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/main-menu" element={<MainMenu/>}/>
        <Route path="/directions" element={<DirectionAPI />} />
        <Route path="/salespoi" element={<SalesPOI />} />
        <Route path="/nearbypoi" element={<NearPOI />} />
        {/* <Route path="/air-quality" element={<AirQualityAPI />} />
        <Route path="/maps-tracking" element={<MapsTracking />} />
        <Route path="/road-check" element={<RoadCheck />} />
        <Route path="/admin" element={<Admin />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
