import React, { useState, useRef, useEffect } from 'react';
import { Box, Input, Text, Flex, List, ListItem, ListIcon, SkeletonText, IconButton, Image, Button, Select } from '@chakra-ui/react';
import { FaMapMarkerAlt, FaBars, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useJsApiLoader, GoogleMap, Marker, InfoWindow, Autocomplete } from '@react-google-maps/api';
import company_logo from './Assets/magna.png';

function NearPOI() {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places', 'geometry'], // Added 'geometry'
  });

  const navigate = useNavigate();
  const [map, setMap] = useState(null);
  const [pois, setPois] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState('');
  const [selectedPoi, setSelectedPoi] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [mapCenter, setMapCenter] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');

  const placesService = useRef(null);
  const addressRef = useRef(null);

  const predefinedKeywords = [
    'place of worship',
    'train station',
    'bus terminal',
    'bus stop',
    'toll road',
    'mall',
    'coffee shop',
    'gas station',
    'school',
  ];

  const markerColors = {
    'place of worship': 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
    'train station': 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
    'bus terminal': 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
    'bus stop': 'http://maps.google.com/mapfiles/ms/icons/orange-dot.png',
    'toll road': 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png',
    'mall': 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
    'coffee shop': 'http://maps.google.com/mapfiles/ms/icons/brown-dot.png',
    'gas station': 'http://maps.google.com/mapfiles/ms/icons/gray-dot.png',
    'school': 'http://maps.google.com/mapfiles/ms/icons/pink-dot.png',
  };

  useEffect(() => {
    if (map) {
      placesService.current = new window.google.maps.places.PlacesService(map);
    }
  }, [map]);

  useEffect(() => {
    if (pois.length > 0 && mapCenter) {
      map.panTo(mapCenter);
    }
  }, [pois, mapCenter, map]);

  const findPoisNearAddress = (location) => {
    setIsLoading(true);
    setPois([]);
    predefinedKeywords.forEach((keyword) => {
      placesService.current.nearbySearch(
        {
          location: location,
          radius: 3000,
          keyword: keyword,
        },
        (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            if (results.length > 0) {
              setMapCenter(results[0].geometry.location);
            }
            // Fetch detailed information for each place
            results.forEach((result) => {
              placesService.current.getDetails({ placeId: result.place_id }, (details, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                  const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
                    location,
                    result.geometry.location
                  );

                  setPois((prevPois) => [
                    ...prevPois,
                    { ...result, ...details, keyword, distance }, // Added distance to the POI object
                  ]);
                }
              });
            });
          } else {
            console.error('Places service failed:', status);
          }
          setIsLoading(false);
        }
      );
    });
  };

  const handlePlaceChanged = () => {
    const place = addressRef.current.getPlace();
    if (place && place.geometry) {
      setAddress(place.formatted_address);
      setMapCenter(place.geometry.location); // Set mapCenter to the searched address
      findPoisNearAddress(place.geometry.location);
    }
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  if (!isLoaded) {
    return <SkeletonText />;
  }

  const filteredPois = selectedCategory === 'All'
    ? pois
    : pois.filter(poi => poi.keyword === selectedCategory);

  return (
    <Flex flexDirection='column' h='100vh' w='100vw' overflow='hidden'>
      {/* Header */}
      <Flex
        bgColor='white'
        color='white'
        p={4}
        alignItems='center'
        justifyContent='space-between'
        position='relative'
        zIndex='1001'
      >
        <Image src={company_logo} alt='Company Logo' boxSize='50px' h='10' w='15' onClick={() => navigate('/main-menu')} />
        <IconButton
          aria-label='Toggle Sidebar'
          icon={<FaBars />}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          position='absolute'
          top='50%'
          right='10px'
          transform='translateY(-50%)'
          variant='outline'
          size='md'
          colorScheme='blue'
        />
      </Flex>

      {/* Main Content */}
      <Flex flex='1' overflow='hidden' position='relative'>
        {/* Sidebar */}
        <Box
          p={4}
          borderRadius='lg'
          bgColor='white'
          shadow='base'
          width='300px'
          overflow='hidden'
          transition='transform 0.3s ease'
          transform={sidebarOpen ? 'translateX(0)' : 'translateX(100%)'}
          position='fixed'
          right='0'
          top='64px'
          height='calc(100% - 64px)'
          zIndex='1000'
          boxShadow='md'
        >
          <Flex direction='column' height='100%' p={4}>
            <Flex justifyContent='space-between' alignItems='center' mb={4}>
              <Text fontSize='lg' fontWeight='bold'>Nearby POIs</Text>
              <IconButton
                aria-label='Clear POIs'
                icon={<FaTrash />}
                onClick={() => setPois([])}
                colorScheme='red'
                size='md'
              />
            </Flex>
            <Flex flexDirection='column' mb={4}>
              <Flex alignItems='center' mb={4}>
                <Autocomplete
                  onPlaceChanged={handlePlaceChanged}
                  onLoad={(autocomplete) => (addressRef.current = autocomplete)}
                >
                  <Input
                    type='text'
                    placeholder='Enter address'
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Autocomplete>
                <Button
                  ml={2}
                  padding={5}
                  onClick={() => {
                    if (addressRef.current && addressRef.current.getPlace()) {
                      const place = addressRef.current.getPlace();
                      if (place && place.geometry) {
                        findPoisNearAddress(place.geometry.location);
                      }
                    }
                  }}
                  colorScheme='blue'
                  size='md'
                >
                  Search POI
                </Button>
              </Flex>
              <Select
                placeholder='Select category'
                mb={4}
                onChange={handleCategoryChange}
                value={selectedCategory}
              >
                <option value='All'>All</option>
                {predefinedKeywords.map((keyword) => (
                  <option key={keyword} value={keyword}>
                    {keyword.charAt(0).toUpperCase() + keyword.slice(1)}
                  </option>
                ))}
              </Select>
            </Flex>
            {isLoading ? (
              <SkeletonText mt='4' noOfLines={4} spacing='4' />
            ) : (
              <Box maxHeight='400px' overflowY='auto'>
                <List spacing={3}>
                  {filteredPois.map((poi, index) => (
                    <ListItem key={index}>
                      <ListIcon as={FaMapMarkerAlt} color='green.500' />
                      <Text fontWeight='bold'>{poi.name}</Text>
                      <Text fontSize='sm'>{poi.vicinity}</Text>
                      <Text fontSize='sm'>Distance: {(poi.distance / 1000).toFixed(2)} km</Text> {/* Displaying the distance */}
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </Flex>
        </Box>

        {/* Map */}
        <Box
          flex='1'
          ml={sidebarOpen ? '0' : '0'}
          height='calc(100vh - 64px)'
          overflow='hidden'
          transition='margin 0.3s ease'
        >
          <GoogleMap
            center={mapCenter || { lat: -6.229354611819955, lng: 106.81945787252144 }}
            zoom={15}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            options={{
              disableDefaultUI: true,
              zoomControl: true,
            }}
            onLoad={(map) => setMap(map)}
          >
            {filteredPois.map((poi, index) => (
              <Marker
                key={index}
                position={poi.geometry.location}
                onClick={() => setSelectedPoi(poi)}
                icon={markerColors[poi.keyword]}
              />
            ))}
            {mapCenter && (
              <Marker
                position={mapCenter} // Marker for the searched address
                icon={{
                  url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                }}
              />
            )}
            {selectedPoi && (
              <InfoWindow
                position={selectedPoi.geometry.location}
                onCloseClick={() => setSelectedPoi(null)}
              >
                <Box>
                  <Text fontSize='lg'>{selectedPoi.name}</Text>
                  <Text fontSize='sm'>{selectedPoi.vicinity}</Text>
                </Box>
              </InfoWindow>
            )}
          </GoogleMap>
        </Box>
      </Flex>
    </Flex>
  );
}

export default NearPOI;
