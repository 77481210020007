import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, Input, Text, Flex, List, ListItem, ListIcon, SkeletonText, IconButton, Image, VStack } from '@chakra-ui/react';
import { FaMapMarkerAlt, FaSearch, FaDirections, FaBars, FaTrash } from 'react-icons/fa';
import { useJsApiLoader, GoogleMap, Marker, DirectionsRenderer, Autocomplete, InfoWindow } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import company_logo from './Assets/magna.png';

function SalesPOI() {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  const navigate = useNavigate();
  const [map, setMap] = useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [pois, setPois] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [keyword, setKeyword] = useState('');
  const [routePath, setRoutePath] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedPoi, setSelectedPoi] = useState(null);
  const [waypoints, setWaypoints] = useState([]);
  const directionsService = useRef(null);
  const placesService = useRef(null);
  const originRef = useRef(null);
  const destinationRef = useRef(null);

  useEffect(() => {
    if (map) {
      directionsService.current = new window.google.maps.DirectionsService();
      placesService.current = new window.google.maps.places.PlacesService(map);
    }
  }, [map]);

  const handleCalculateRoute = () => {
    if (!origin || !destination) return;

    setIsLoading(true);

    const directionsServiceOptions = {
      origin,
      destination,
      travelMode: window.google.maps.TravelMode.DRIVING,
      waypoints: waypoints.map(poi => ({
        location: poi.geometry.location,
        stopover: true
      })),
    };

    directionsService.current.route(directionsServiceOptions, (results, status) => {
      setIsLoading(false);
      if (status === window.google.maps.DirectionsStatus.OK) {
        setDirectionsResponse(results);
        const path = results.routes[0].overview_path.map(point => ({
          lat: point.lat(),
          lng: point.lng(),
        }));
        setRoutePath(path);
        findPoisAlongRoute(path);
      } else {
        console.error('Directions service failed:', status);
        setDirectionsResponse(null);
        setRoutePath([]);
      }
    });
  };

  const findPoisAlongRoute = async (path) => {
    if (!keyword || path.length === 0) return;

    setIsLoading(true);
    const bounds = new window.google.maps.LatLngBounds();
    path.forEach(point => bounds.extend(new window.google.maps.LatLng(point.lat, point.lng)));

    placesService.current.nearbySearch({
      bounds: bounds,
      keyword: keyword,
    }, (results, status) => {
      setIsLoading(false);
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setPois(results);
      } else {
        console.error('Places service failed:', status);
        setPois([]);
      }
    });
  };

  const handlePlaceChanged = (place, setter) => {
    setter(place.formatted_address);
  };

  const handleMarkerClick = (poi) => {
    setIsLoading(true);
    placesService.current.getDetails({ placeId: poi.place_id }, (details, status) => {
      setIsLoading(false);
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setSelectedPoi(details);
        setWaypoints([...waypoints, details]);
      } else {
        console.error('Places service failed:', status);
        setSelectedPoi(null);
      }
    });
  };

  const handleClearRoutes = () => {
    setDirectionsResponse(null);
    setRoutePath([]);
    setWaypoints([]);
    setPois([]);
    setSelectedPoi(null);
  };

  if (!isLoaded) {
    return <SkeletonText />;
  }

  return (
    <Flex flexDirection='column' h='100vh' w='100vw' overflow='hidden'>
      {/* Header */}
      <Flex
        bgColor='white'
        color='white'
        p={4}
        alignItems='center'
        justifyContent='space-between'
        position='relative'
        zIndex='1001'
      >
        <Image src={company_logo} alt='Company Logo' boxSize='50px' h='10' w='15' onClick={() => navigate('/main-menu')} />
        <IconButton
          aria-label='Toggle Sidebar'
          icon={<FaBars />}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          position='absolute'
          top='50%'
          right='10px'
          transform='translateY(-50%)'
          variant='outline'
          size='md'
          colorScheme='blue'
        />
      </Flex>

      {/* Main Content */}
      <Flex flex='1' overflow='hidden' position='relative'>
        {/* Sidebar */}
        <Box
          p={4}
          borderRadius='lg'
          bgColor='white'
          shadow='base'
          width='300px'
          overflow='hidden'
          transition='transform 0.3s ease'
          transform={sidebarOpen ? 'translateX(0)' : 'translateX(100%)'}
          position='fixed'
          right='0'
          top='64px'
          height='calc(100% - 64px)'
          zIndex='1000'
          boxShadow='md'
        >
          <Flex direction='column' height='100%' p={4}>
            <Flex justifyContent='space-between' alignItems='center' mb={4}>
              <Text fontSize='lg' fontWeight='bold'>Search POI by Route</Text>
              <IconButton
                aria-label='Clear Routes'
                icon={<FaTrash />}
                onClick={handleClearRoutes}
                colorScheme='red'
                size='md'
              />
            </Flex>
            <Flex flexDirection='column' mb={4}>
              <Flex alignItems='center' mb={4}>
                <Box flex='1' mr={2}>
                  <Autocomplete
                    onPlaceChanged={() => handlePlaceChanged(originRef.current.getPlace(), setOrigin)}
                    onLoad={(autocomplete) => originRef.current = autocomplete}
                  >
                    <Input
                      type='text'
                      placeholder='Origin'
                      value={origin}
                      onChange={(e) => setOrigin(e.target.value)}
                    />
                  </Autocomplete>
                </Box>
                <Box flex='1' ml={2}>
                  <Autocomplete
                    onPlaceChanged={() => handlePlaceChanged(destinationRef.current.getPlace(), setDestination)}
                    onLoad={(autocomplete) => destinationRef.current = autocomplete}
                  >
                    <Input
                      type='text'
                      placeholder='Destination'
                      value={destination}
                      onChange={(e) => setDestination(e.target.value)}
                    />
                  </Autocomplete>
                </Box>
              </Flex>
              <Flex alignItems='center' mb={4}>
                <Box flex='1' mr={2}>
                  <Autocomplete
                    onLoad={(autocomplete) => autocomplete.addListener('place_changed', () => handlePlaceChanged(autocomplete.getPlace(), setKeyword))}
                  >
                    <Input
                      type='text'
                      placeholder='Enter keyword for POI'
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </Autocomplete>
                </Box>
                <Button
                  colorScheme='blue'
                  ml={2}
                  onClick={() => findPoisAlongRoute(routePath)}
                  isLoading={isLoading}
                  loadingText='Searching'
                >
                  <FaSearch style={{ marginRight: '8px' }} />
                  Search POI
                </Button>
              </Flex>
              <Box mb={4}>
                <Button
                  colorScheme='blue'
                  onClick={handleCalculateRoute}
                  isLoading={isLoading}
                  loadingText='Calculating'
                  width='full'
                >
                  <FaDirections style={{ marginRight: '8px' }} />
                  Calculate Route
                </Button>
              </Box>
              {isLoading ? (
                <SkeletonText mt='4' noOfLines={4} spacing='4' />
              ) : (
                <Box maxHeight='400px' overflowY='auto'>
                  <List spacing={3}>
                    {pois.map((poi, index) => (
                      <ListItem key={index}>
                        <ListIcon as={FaMapMarkerAlt} color='green.500' />
                        <Text fontWeight='bold'>{poi.name}</Text>
                        <Text fontSize='sm'>{poi.vicinity}</Text>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
            </Flex>
          </Flex>
        </Box>

        {/* Map */}
        <Box
          flex='1'
          ml={sidebarOpen ? '0' : '0'}
          height='calc(100vh - 64px)'
          overflow='hidden'
          transition='margin 0.3s ease'
        >
          <GoogleMap
            center={map ? map.getCenter() : { lat: -6.229354611819955, lng: 106.81945787252144 }}
            zoom={15}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            onLoad={map => setMap(map)}
          >
            {directionsResponse && (
              <DirectionsRenderer directions={directionsResponse} />
            )}
            {pois.map((poi, index) => (
              <Marker
                key={index}
                position={{ lat: poi.geometry.location.lat(), lng: poi.geometry.location.lng() }}
                onClick={() => handleMarkerClick(poi)}
              />
            ))}
            {selectedPoi && (
              <InfoWindow
                position={{
                  lat: selectedPoi.geometry.location.lat(),
                  lng: selectedPoi.geometry.location.lng()
                }}
                onCloseClick={() => setSelectedPoi(null)}
              >
                <Box>
                  <Flex alignItems='center' mb={2}>
                    {selectedPoi.photos && selectedPoi.photos[0] && (
                      <Image
                        src={selectedPoi.photos[0].getUrl({ maxWidth: 100 })}
                        alt='POI Photo'
                        boxSize='100px'
                        objectFit='cover'
                        mr={2}
                      />
                    )}
                    <VStack align='start' spacing={1} flex='1'>
                      <Text fontSize='lg' fontWeight='bold'>{selectedPoi.name}</Text>
                      <Text fontSize='sm'>{selectedPoi.vicinity}</Text>
                      <Text fontSize='sm' color='gray.500'>Rating: {selectedPoi.rating ? selectedPoi.rating : 'N/A'}</Text>
                      <Text fontSize='sm' color='gray.500'>Address: {selectedPoi.formatted_address}</Text>
                      <Text fontSize='sm' color='gray.500'>Opening Hours:</Text>
                      {selectedPoi.opening_hours ? (
                        <Box>
                          <Text fontSize='sm'>
                            {selectedPoi.opening_hours.open_now ? 'Open Now' : 'Closed Now'}
                          </Text>
                          {selectedPoi.opening_hours.weekday_text.map((hour, index) => (
                            <Text key={index} fontSize='sm'>{hour}</Text>
                          ))}
                        </Box>
                      ) : (
                        <Text fontSize='sm' color='gray.500'>Information not available</Text>
                      )}
                    </VStack>
                  </Flex>
                </Box>
              </InfoWindow>
            )}
          </GoogleMap>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SalesPOI;
